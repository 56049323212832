import React from 'react';

import type { IUser } from 'common/store/user/userSlice';
import { stringToHslColor } from 'common/utils/stringToHslColor';

import Avatar from '../../ui/Avatar/Avatar';

export default function UserDetails({
  user,
  onClick
}: {
  readonly user: IUser;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const fullName = `${user.given_name} ${user.family_name}`.trim();

  return (
    <button className="user-det" onClick={onClick}>
      <Avatar
        size={32}
        image=""
        alt={(user.given_name || user.name) ? fullName || user.email : user.email}
        backgroundColor={stringToHslColor(fullName, 30, 80)}
      />
      <div className="acct-det">
        {(user.given_name || user.name) && 
          <div className="usr-name ellipsis">{fullName || user.name}</div>
        }
        <div className={`ellipsis ${(user.given_name || user.name) ? 'usr-email' : 'usr-name'}`}>{user.email}</div>
      </div>
    </button>
  );
}
